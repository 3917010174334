import moment from 'moment'
export default {
	removeNonNumericCharsOMD(phone) {
		return phone.replace(/\D/g, '')
	},

	// Method to format the phone number as xxx-xxx-xxxx
	newFormatPhoneNumberOMD(phone) {
		const cleaned = ('' + phone).replace(/\D/g, '') // Remove non-numeric characters
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

		if (match) {
			return `${match[2]}-${match[3]}-${match[4]}`
		}

		return phone // If it doesn't match the format, return the original
	},

	validateCallBackNumberDashed(number) {
		return !(
			!number ||
			number.length < 12 ||
			number[0] === '0' ||
			number[0] === '1'
		)
	},

	formatPhoneNumberAsStandard(number) {
		// Remove non-numeric characters
		let formatted = number.replace(/\D/g, '')

		// Add the phone number format (xxx-xxx-xxxx)
		if (formatted.length <= 3) {
			number = formatted // First part
		} else if (formatted.length <= 6) {
			number = `${formatted.slice(0, 3)}-${formatted.slice(3)}` // Second part
		} else {
			number = `${formatted.slice(0, 3)}-${formatted.slice(
				3,
				6
			)}-${formatted.slice(6, 10)}` // Final format
		}
		return number
	},

	formatDateWithTime(date) {
		// Use moment to format the date
		return moment(date).format('MM/DD/YYYY hh:mm A')
	},
}
