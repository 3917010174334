<template>
	<div
		v-if="currentTemplateType === nicklausTemplateType"
		class="padding-navbar w-full flex font-roboto overflow-auto"
	>
		<form
			class="m-5 w-75 flex-box  bg-white rounded border border-gray-400 p-4 mx-auto"
			style="height: fit-content;"
			@submit.prevent="nicklausOnSumbit"
		>
			<!-- Hospital -->
			<div class="mb-2 flex flex-col ">
				<label for="provider" class="font-nunito font-medium">
					Hospital
				</label>
				<multiselect
					ref="multiselect"
					v-model="hospitalCallType"
					track-by="id"
					label="label"
					placeholder="Select a Hospital"
					:options="types"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
				>
					<template slot="noResult">
						No Hospital Found
					</template>
				</multiselect>
			</div>
			<!-- Specialty -->
			<div
				:class="[
					'mb-2',
					'flex',
					'flex-col',
					hasNoCalendars ? 'speciality-container-red' : '',
				]"
			>
				<label for="provider" class="font-nunito font-medium">
					Specialty
				</label>
				<multiselect
					ref="multiselect"
					v-model="selectedCalender"
					track-by="id"
					label="label"
					:options="userAllowedCalendersFormatted"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
					:disabled="hasNoCalendars"
				>
					<template slot="noResult">
						No Specialty Found
					</template>
					<!-- Custom placeholder for empty options -->
					<template v-slot:placeholder>
						<span v-if="hasNoCalendars" class="no-speciality-text">
							No specialty is configured. Will not be able to
							submit page. Contact Clarus.
						</span>
						<span v-else>Select a Specialty</span>
					</template>
				</multiselect>
			</div>
			<!-- Patient Name with 50 char max length -->
			<div class="mb-2 flex flex-col">
				<label for="name" class="font-nunito font-medium">
					Patient Name
				</label>
				<input
					v-model="name"
					type="text"
					name="name"
					maxlength="50"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter Patient Name"
				/>
			</div>
			<!-- DOB with strick mm/dd/yyyy -->
			<div class="mb-3 flex flex-col">
				<label for="dob" class="font-nunito font-medium">
					Patient DOB
				</label>
				<div class="form-item__inputField">
					<input
						ref="dob"
						type="text"
						class="shadow appearance-none form-item__inputField--editable outline-none font-medium border rounded w-full py-2 px-3 text-gray-700 focus:shadow-outline"
						:value="dob.value"
						placeholder="MM/DD/YYYY"
						maxlength="10"
						@input="handleDobInput"
					/>
					<div
						v-if="dobErrorMessage"
						class="text-red-500 text-sm mt-2"
					>
						{{ dobErrorMessage }}
					</div>
				</div>
			</div>
			<!-- Gender -->
			<div class="mb-2 flex flex-col ">
				<label for="selectedGender" class="font-nunito font-medium">
					Gender
				</label>
				<div class="flex justify-items-start">
					<span
						v-for="option in genders"
						:key="option.id"
						class="flex items-center mx-2"
					>
						<!-- Radio Button -->
						<input
							:id="option.id"
							v-model="selectedGender"
							type="radio"
							:value="option.label"
							class="-mt-2"
						/>
						<!-- Label Text -->
						<label :for="option.id" class="px-1">{{
							option.label
						}}</label>
					</span>
				</div>
			</div>
			<!-- Patient MRN -->
			<div class="mb-2 flex flex-col">
				<label for="patientMRN" class="font-nunito font-medium">
					Patient MRN
				</label>
				<input
					v-model="patientMRN"
					type="text"
					name="patientMRN"
					minlength="5"
					maxlength="25"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter Patient MRN"
				/>
			</div>
			<div class="mb-2 flex flex-col">
				<label for="callback" class="font-nunito font-medium">
					Callback Number
				</label>
				<input
					v-model="nicklausCallbackNumber"
					type="text"
					name="callback"
					maxlength="12"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="nnn-nnn-nnnn"
					@input="formatPhoneNumber"
				/>
				<div
					v-if="callbackNumberErrorMessage"
					class="text-red-500 text-sm mt-2"
				>
					{{ callbackNumberErrorMessage }}
				</div>
			</div>
			<!-- Ordering Provider -->
			<div class="mb-2 flex flex-col">
				<label for="orderingProvider" class="font-nunito font-medium">
					Ordering Provider
				</label>
				<input
					v-model="orderingProvider"
					type="text"
					name="orderingProvider"
					minlength="3"
					maxlength="50"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter Ordering Provider Name"
				/>
			</div>
			<!-- Reason for Consult:  -->
			<div class="flex flex-col">
				<label for="resonForConsult" class="font-nunito font-medium">
					Reason for Consult
				</label>
				<textarea
					v-model="resonForConsult"
					name="resonForConsult"
					minlength="3"
					maxlength="400"
					placeholder="Enter Reason for Consult"
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-2 font-roboto"
					style="height:250px"
				/>
			</div>
			<!-- Room Number -->
			<div class="mb-2 flex flex-col">
				<label for="roomNumber" class="font-nunito font-medium">
					Room Number (Optional)
				</label>
				<input
					v-model="roomNumber"
					type="text"
					name="roomNumber"
					minlength="1"
					maxlength="25"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter Room Number"
				/>
			</div>
			<div class="mb-2 flex justify-end ">
				<button
					type="submit"
					class="btn btn-outline-success ml-2"
					:disabled="isFormSubmitting"
				>
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'paper-plane']"
					/>
					<span class="btn-label">
						Send
					</span>
				</button>
			</div>
		</form>
	</div>
	<div v-else class="padding-navbar w-full flex font-roboto overflow-auto">
		<form
			class="m-5 w-75 flex-box h-full bg-white rounded border border-gray-400 p-4 mx-auto"
			@submit.prevent="onSubmit"
		>
			<div class="mb-2 flex flex-col ">
				<label for="provider" class="font-nunito font-medium">
					Select provider to notify
				</label>
				<multiselect
					ref="multiselect"
					v-model="provider"
					track-by="id"
					label="label"
					placeholder="Select a provider"
					:options="providers"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
				>
					<template slot="noResult">
						No providers found
					</template>
				</multiselect>
			</div>
			<div class="mb-2 flex flex-col">
				<label for="callback" class="font-nunito font-medium">
					Callback Number
				</label>
				<input
					v-model="callbackNumber"
					required
					type="text"
					name="callback"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter callback number"
					@input="validateCallbackNumber"
				/>
				<div
					v-if="callbackNumberErrorMessage"
					class="text-red-500 text-sm mt-2"
				>
					{{ callbackNumberErrorMessage }}
				</div>
			</div>
			<div class="mb-2 flex flex-col">
				<label for="name" class="font-nunito font-medium">
					Patient Name
				</label>
				<input
					v-model="name"
					required
					type="text"
					name="name"
					maxlength="50"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter patient name"
				/>
			</div>

			<div class="flex flex-col">
				<label for="message" class="font-nunito font-medium">
					Patient Message
				</label>
				<textarea
					v-model="message"
					required
					name="message"
					placeholder="Enter patient message"
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-2 font-roboto"
					style="height:250px"
				/>
			</div>
			<div class="mb-3 flex flex-col">
				<label for="dob" class="font-nunito font-medium">
					Patient DOB (Optional)
				</label>
				<div class="form-item__inputField">
					<input
						ref="dob"
						type="text"
						class="shadow appearance-none form-item__inputField--editable outline-none font-medium border rounded w-full py-2 px-3 text-gray-700 focus:shadow-outline"
						:value="dob.value"
						placeholder="MM/DD/YYYY"
						maxlength="10"
						@input="handleDobInput"
					/>
					<div
						v-if="dobErrorMessage"
						class="text-red-500 text-sm mt-2"
					>
						{{ dobErrorMessage }}
					</div>
				</div>
			</div>
			<div class="mb-2 flex justify-end ">
				<button
					type="submit"
					class="btn btn-outline-success ml-2"
					:disabled="isFormSubmitting"
				>
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'paper-plane']"
					/>
					<span class="btn-label">
						Send
					</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters, mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import DateEditable from 'App/Support/DateEditable'
import moment from 'moment'
import util from '@/util'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Multiselect,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all the providers in the needed format.
		 *
		 * @return {Array}
		 */
		providers() {
			if (!this.internalProviders) {
				return []
			}

			return this.internalProviders.map(provider => {
				return {
					id: provider.id,
					label: provider.full_name,
				}
			})
		},
		types() {
			return (this.internalHospitalCallTypes || [])
				.sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by the 'name' property
				.map(type => {
					return {
						id: type.id,
						label: type.name,
					}
				})
		},

		userAllowedCalendersFormatted() {
			return this.receivedCalendars
		},

		currentTemplateType() {
			return this.partner?.paging_template_type
		},

		...mapGetters({
			partner: 'partners/active',
			internalProviders: 'providers/internal',
			internalHospitalCallTypes: 'callTypes/get',
		}),

		...mapState('auth', {
			user: state => state.user || null,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		//this method will validate the callback number
		validateCallbackNumber(event) {
			const value = event.target.value || ''
			this.callbackNumber = value.replace(/[^0-9]/g, '')
			const sanitizedPhoneNumber = this.callbackNumber

			// No error message until at least 10 digits are entered
			if (sanitizedPhoneNumber.length < 10) {
				this.callbackNumberErrorMessage = ''
				return true
			}

			// Validate the format once 10 digits are entered
			if (!/^[2-9]\d{9}$/.test(sanitizedPhoneNumber)) {
				this.callbackNumberErrorMessage =
					'Callback Number has to be a valid 10 digit number.'
				return false
			}

			this.callbackNumberErrorMessage = ''
			return true
		},
		formatPhoneNumber() {
			this.nicklausCallbackNumber = util.formatPhoneNumberAsStandard(
				this.nicklausCallbackNumber
			)
		},

		handleDobInput(event) {
			let value = event.target.value
			value = value.replace(/[^0-9/]/g, '')
			event.target.value = value
			this.dob.onInput(event)
			this.dobError = !this.validateDob(value)
			// Allow empty DOB field to pass validation
			if (value.trim() === '') {
				this.dobError = false
				this.dobErrorMessage = ''
			}
		},

		validateDob(dateString) {
			const format = 'MM/DD/YYYY'
			if (dateString.trim() === '') return false

			const datePattern = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/
			if (!datePattern.test(dateString)) {
				return false
			}
			const [month, day, year] = dateString.split('/').map(Number)

			// Check for valid ranges
			if (month < 1 || month > 12) return false
			if (year < 1000 || year > 3000) return false

			// Check for day validity based on month and leap year
			const daysInMonth = new Date(year, month, 0).getDate()
			if (day < 1 || day > daysInMonth) return false

			// Check if the DOB is in the future
			const today = moment() // Current date
			const dob = moment(dateString, format, true)
			if (dob.isAfter(today, 'day')) {
				// Check if entered date is after today
				return false
			}

			const date = moment(dateString, format, true)
			const isValid = date.isValid() && date.format(format) === dateString

			if (isValid) {
				this.dobErrorMessage = false
			}

			return isValid
		},

		async fetchCalendars() {
			if (this.currentTemplateType === this.nicklausTemplateType) {
				try {
					const activePartnerId = this.partner?.id
					const calendarsResponse = await this.$api
						.calendars()
						.get(activePartnerId)
					const calendarList = calendarsResponse.get('data', [])

					// Get the calendars from the user's accessible calendars
					const calendars =
						this.user?.accessible_calendars?.[0]?.calendars || []

					// Find the matching calendars from the calendar list
					const received = calendars
						.map(id =>
							calendarList.find(i => i.id === parseInt(id))
						)
						.filter(Boolean)

					this.hasNoCalendars = received.length === 0

					// Format the received calendars for the UI
					this.receivedCalendars = received
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(calendar => ({
							id: calendar.id,
							label: calendar.name,
						}))
				} catch (error) {
					this.receivedCalendars = []
					this.hasNoCalendars = true
				}
			}
		},
		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async nicklausOnSumbit() {
			try {
				this.isFormSubmitting = true
				if (this.hospitalCallType === null) {
					this.$alert.error('Hospital is required.')
					return
				}
				if (this.selectedCalender === null) {
					this.$alert.error('Specialty is required.')
					return
				}
				if (this.name.length === 0) {
					this.$alert.error('Patient Name is required.')
					return
				}
				if (this.name.length > 50) {
					this.$alert.error(
						'Patient Name should be less than 50 characters.'
					)
					return
				}
				if (!this.dob.value) {
					this.$alert.error('Date of birth is required.')
					return
				}
				if (this.dob.value.length < 10) {
					this.$alert.error('Enter the complete date of birth.')
					return
				}
				if (this.dobError) {
					this.$alert.error('Please enter a valid date of birth')
					this.dobErrorMessage = 'Please enter a valid date of birth'
					return
				}
				if (this.selectedGender === null) {
					this.$alert.error('Gender is required.')
					return
				}
				if (!this.patientMRN) {
					this.$alert.error('Patient MRN is required')
					return
				}
				if (this.patientMRN.length < 5 || this.patientMRN.length > 25) {
					this.$alert.error(
						'Patient MRN must be between 5 and 25 characters.'
					)
					return
				}
				if (
					!util.validateCallBackNumberDashed(
						this.nicklausCallbackNumber
					)
				) {
					this.$alert.error(
						'Callback Number has to be a valid 10 digit number.'
					)
					return
				}
				if (this.nicklausCallbackNumber) {
					this.cleanedPhoneNumber = this.nicklausCallbackNumber.replace(
						/-/g,
						''
					)
				}
				if (!this.orderingProvider) {
					this.$alert.error('Ordering Provider is required')
					return
				}
				if (
					this.orderingProvider.length < 3 ||
					this.orderingProvider.length > 50
				) {
					this.$alert.error(
						'Ordering Provider must be between 3 and 50 characters.'
					)
					return
				}
				if (!this.resonForConsult) {
					this.$alert.error('Reason for Consult is required')
					return
				}
				if (
					this.resonForConsult.length < 3 ||
					this.resonForConsult.length > 400
				) {
					this.$alert.error(
						'Reason for consult must be between 3 and 400 characters.'
					)
					return
				}

				// post request
				const response = await this.create({
					partner_id: this.$store.state.partners.active,
					patient_name: this.name,
					patient_dob: this.dob.value,
					callback_number: this.cleanedPhoneNumber,
					call_type_id: this.hospitalCallType.id,
					calendar_id: this.selectedCalender.id,
					patient_message: `Hospital: ${
						this.hospitalCallType.label
					}\nSpecialty: ${
						this.selectedCalender.label
					}\nPatient Gender: ${this.selectedGender}\nPatient MRN: ${
						this.patientMRN
					}\nOrdering Provider: ${this.orderingProvider}${
						this.roomNumber
							? `\nRoom Number: ${this.roomNumber}`
							: ''
					}\nReason for Consult: \n${this.resonForConsult}`,
				})

				const { sid } = response.get('call')
				const currentDate = this.getCurrentDate()
				const filterEndDate = moment(
					this.$store.state.pages.filters.ends
				).format('YYYY-MM-DD')

				if (filterEndDate === currentDate) {
					this.fetchFilteredPages()
				}

				this.$router.push({
					name: 'app.paging.show',
					params: { sid },
				})

				this.$alert.success('pages.create.success')
			} catch (e) {
				this.$alert.error('pages.create.error')
			} finally {
				this.isFormSubmitting = false
			}
		},

		// For Default
		async onSubmit() {
			try {
				this.isFormSubmitting = true
				if (this.callbackNumber.length < 10) {
					this.callbackNumberErrorMessage =
						'Callback Number must be at least 10 digits.'
					this.$alert.error(this.callbackNumberErrorMessage)
					return
				}

				if (this.dobError) {
					this.$alert.error('Please enter a valid date of birth')
					this.dobErrorMessage = 'Please enter a valid date of birth'
					return
				}

				// Validate the format of the number
				if (
					!this.validateCallbackNumber({
						target: { value: this.callbackNumber },
					})
				) {
					this.$alert.error(this.callbackNumberErrorMessage)
					return
				}
				const response = await this.create({
					provider_id: this.provider.id,
					callback_number: this.callbackNumber,
					patient_name: this.name,
					patient_message: this.message,
					patient_dob: this.dob.value,
					partner_id: this.$store.state.partners.active,
				})

				const { sid } = response.get('call')
				const currentDate = this.getCurrentDate()
				const filterEndDate = moment(
					this.$store.state.pages.filters.ends
				).format('YYYY-MM-DD')

				if (filterEndDate === currentDate) {
					this.fetchFilteredPages()
				}

				this.$router.push({
					name: 'app.paging.show',
					params: { sid },
				})

				this.$alert.success('pages.create.success')
			} catch (e) {
				this.$alert.error('pages.create.error')
			} finally {
				this.isFormSubmitting = false
			}
		},

		getCurrentDate() {
			return moment().format('YYYY-MM-DD')
		},

		async fetchFilteredPages() {
			try {
				await this.$store.dispatch('pages/get')
			} catch (error) {
				console.error('Error fetching filtered pages:', error)
			}
		},

		clearErrorForm() {
			;(this.callbackNumberErrorMessage = ''),
				(this.callbackNumber = null),
				(this.message = ''),
				(this.name = ''),
				(this.provider = null),
				(this.dob = new DateEditable()),
				(this.dobErrorMessage = false),
				(this.resonForConsult = ''),
				(this.hospitalCallType = null),
				(this.selectedGender = null),
				(this.selectedCalender = null),
				(this.nicklausCallbackNumber = null),
				(this.patientMRN = null),
				(this.roomNumber = null),
				(this.cleanedPhoneNumber = ''),
				(this.userAllowedCalenders = null),
				(this.calendarList = []),
				(this.receivedCalendars = [])
		},

		...mapActions({
			create: 'pages/create',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Create',

	watch: {
		'partner.id': {
			handler(newPartnerId, oldPartnerId) {
				if (newPartnerId !== oldPartnerId) {
					this.$store.dispatch('callTypes/get')
					this.clearErrorForm()
					this.message = this.partner?.paging_template || ''
					this.fetchCalendars()
				}
			},
			immediate: true,
		},
	},

	beforeDestroy() {
		this.hasNoCalendars = false
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			callbackNumber: null,
			message: '',
			name: '',
			provider: null,
			dob: new DateEditable(),
			callbackNumberErrorMessage: '',
			dobErrorMessage: false,
			orderingProvider: null,
			hospitalCallType: null,
			patientMRN: null,
			roomNumber: null,
			nicklausCallbackNumber: null,
			cleanedPhoneNumber: '',
			selectedGender: null,
			genders: [
				{ id: 'female', label: 'Female' },
				{ id: 'male', label: 'Male' },
				{ id: 'other', label: 'Other' },
			],
			selectedCalender: null,
			resonForConsult: null,
			nicklausTemplateType: 'nicklaus_ncps',
			calendarList: [],
			receivedCalendars: [],
			isFormSubmitting: false,
			hasNoCalendars: false,
		}
	},
}
</script>
